<template>
  <div>
    <v-text-field
      ref="inputField"
      v-bind="$attrs"
      class="flex-grow-1"
      :label="$translation.t('Address')"
      v-model="model"
      outlined
      :readonly="disabled"
      hide-details="auto"
      :class="{ 'input-disabled': disabled }"
    >
    </v-text-field>
  </div>
</template>

<script>
import projectInputFields from "@/utils/mixins/projectInputFields";

export default {
  name: "AddressField",
  props: {
    value: String,
    global: {
      type: Boolean,
      default: true,
    },
  },
  mixins: [projectInputFields],
  data: () => ({
    model: "",
  }),
  created() {
    if (this.global) this.model = this.address ?? "";
  },
  mounted() {
    this.model = this.value ?? this.model;
  },
  computed: {
    address() {
      return this.projectInformationValue("address");
    },
  },
  watch: {
    model(newPhone) {
      this.$emit("input", newPhone);
    },
    value(newValue) {
      this.model = newValue;
    },
    address(newValue) {
      if (this.global) this.model = newValue;
    },
  },
};
</script>

<style scoped></style>
